// src/components/CustomDataTable.js
import { Components } from "formiojs";

const FieldComponent = Components.components.htmlelement;

class CustomDataTable extends FieldComponent {
  static schema(...extend) {
    return FieldComponent.schema({
      label: "Custom Data Table",
      type: "customdatatable",
      key: "customDataTable",
      input: true,
      ...extend,
      tag: "div",
      className: "row mt-4",
      attrs: [
        {
          attr: "",
          value: "",
        },
      ],
      content: `<section class="datatables">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
            <div class="mb-2">
              <h5 class="mb-0">{{component.tableLabel}}</h5>
            </div>
            <div class="table-responsive">
              <table id="{{component.tableid}}" class="table border table-striped table-bordered text-nowrap">
                <thead>
                  <tr>
                    {{component.tableHeader.map(item=> "<th>"+ item.label +"</th>").join("")}}
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>`,
      customConditional:
        "const token1 = localStorage.getItem('token');\r\nconst uri = component.apiUrl;\r\nconst columns = component.tableHeader.map(item => { const selectBoxes = item.selectBoxes; if (selectBoxes.edit || selectBoxes.delete) { return { data: item.labelvalue, render: function(data, type, row) { var actions = ''; if(selectBoxes.edit) actions += '<button type=\"button\" title=\"Edit\" class=\"btn btn-info edit-btn\" data-id=\"' + data + '\"><i class=\"ti ti-pencil\"></i></button>'; if(selectBoxes.delete) actions += '<button type=\"button\" title=\"Delete\" class=\"btn btn-danger delete-btn\" data-id=\"' + data + '\"><i class=\"ti ti-trash\"></i></button>'; return actions; } }; } else { return { data: item.labelvalue }; } });\r\nvar btn=[];\r\nif(component.tableisexcel) btn.push({ extend: 'excel', className: 'btn btn-primary' });\r\nif(component.tableispdf) btn.push({ extend: 'pdf', className: 'btn btn-primary' });\r\n$('#'+component.tableid).DataTable({ 'processing': true, 'serverSide': true, 'destroy': true, 'ajax': { 'url': uri, 'headers' : { 'x-jwt-token': token1 }, 'type': 'GET', 'data': function(d) { var page_number = (d.start / d.length) + 1; return $.extend({}, d, {'skip': page_number ==1?0:d.length*(page_number -1), 'limit': d.length}); }, 'dataSrc': function(json) { json.recordsTotal = 17; json.recordsFiltered = json.recordsTotal; return json; } }, 'columns': columns, 'paging': true, 'pageLength': 10, 'lengthMenu': [10, 25, 50, 100], 'dom': 'lBfrtip', 'buttons': btn });",

      //"const token1 = localStorage.getItem('token');\r\nconst uri = component.apiUrl;\r\nconst columns = component.tableHeader.map(item => { const selectBoxes = item.selectBoxes; if (selectBoxes.edit || selectBoxes.delete) { return { data: item.labelvalue, render: function(data, type, row) { var actions = ''; if(selectBoxes.edit) actions += '<button type=\"button\" title=\"Edit\" class=\"btn btn-info edit-btn\" data-id=\"' + data + '\"><i class=\"ti ti-pencil\"></i></button>'; if(selectBoxes.delete) actions += '<button type=\"button\" title=\"Delete\" class=\"btn btn-danger delete-btn\" data-id=\"' + data + '\"><i class=\"ti ti-trash\"></i></button>'; return actions; } }; } else { return { data: item.labelvalue }; } });\r\nvar btn=[];if(component.tableisexcel)	btn.push({ extend: 'excel', className: 'btn btn-primary' });if(component.tableispdf)	btn.push({ extend: 'pdf', className: 'btn btn-primary' });\r\n$.ajax({ url: uri, type: 'GET', headers: { 'Content-Type': 'application/json', 'x-jwt-token': token1 }, success: function(data) { $('#'+component.tableid).DataTable({ dom: 'lBfrtip', buttons: btn, data: data, destroy: true, columns: columns }); } });",
    });
  }

  static get builderInfo() {
    return {
      title: "Custom Data Table",
      group: "custom",
      icon: "html5",
      weight: 70,
      schema: CustomDataTable.schema(),
    };
  }

  constructor(component, options, data) {
    super(component, options, data);
  }
}

Components.addComponent("customdatatable", CustomDataTable);

export default CustomDataTable;
