import React from "react";
import "./css/Layout.css";
import { Navigate, useNavigate } from "react-router-dom";
const Layout = ({ children }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  if (!token) {
    return <Navigate to="/" />;
  }

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-theme="blue_theme"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="body-wrapper">
          <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link sidebartoggler nav-icon-hover ms-n3"
                    href="#"
                  >
                    <img src="logo.png" width={120} height={30} />
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav quick-links d-none d-lg-flex">
                <li className="nav-item dropdown-hover d-none d-lg-block">
                  <a className="nav-link" href="/dashboard">
                    Home
                  </a>
                </li>
                <li className="nav-item dropdown-hover d-none d-lg-block">
                  <a className="nav-link" href="/designer">
                    Form
                  </a>
                </li>

                <li className="nav-item dropdown hover-dd d-none d-lg-block">
                  <a className="nav-link" href="#" data-bs-toggle="dropdown">
                    Management
                    <span className="mt-1">
                      <i className="ti ti-chevron-down"></i>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-nav dropdown-menu-animate-up py-0">
                    <div className="row">
                      <div className="col-12">
                        <div className=" ps-7 pt-7">
                          <div className="border-bottom">
                            <div className="row">
                              <div className="col-4">
                                <div className="position-relative">
                                  <a
                                    href="/project"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-chat.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Project Master
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Create a new project here
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="/formworkflowmapper"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-invoice.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Form Workflow mapper
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Map workflow here
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-contact2.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-mobile.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Role
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Create a new role here
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-email.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-message-box.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Role Mapping
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Role assign here to user
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="position-relative">
                                  <a
                                    href="page-user-profile.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-cart.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        User Profile
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        learn more information
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-calendar.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-date.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Calendar App
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Get dates
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-contact.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-lifebuoy.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Contact List Table
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Add new contact
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-notes.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-application.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Notes Application
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        To-do and Daily tasks
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="position-relative">
                                  <a
                                    href="page-user-profile.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-cart.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        User Profile
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        learn more information
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-calendar.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-date.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Calendar App
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Get dates
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-contact.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-lifebuoy.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Contact List Table
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        Add new contact
                                      </span>
                                    </div>
                                  </a>
                                  <a
                                    href="app-notes.html"
                                    className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                  >
                                    <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                      <img
                                        src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-application.svg"
                                        alt=""
                                        className="img-fluid"
                                        width="24"
                                        height="24"
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <h6 className="mb-1 fw-semibold bg-hover-primary">
                                        Notes Application
                                      </h6>
                                      <span className="fs-2 d-block text-dark">
                                        To-do and Daily tasks
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-4 ms-n4">
                        <div className="position-relative p-7 border-start h-100">
                          <h5 className="fs-5 mb-9 fw-semibold">Quick Links</h5>
                          <ul className="">
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="page-pricing.html"
                              >
                                Pricing Page
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="authentication-login.html"
                              >
                                Authentication Design
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="authentication-register.html"
                              >
                                Register Now
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="authentication-error.html"
                              >
                                404 Error Page
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="app-notes.html"
                              >
                                Notes App
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="page-user-profile.html"
                              >
                                User Application
                              </a>
                            </li>
                            <li className="mb-3">
                              <a
                                className="fw-semibold text-dark bg-hover-primary text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
                                href="page-account-settings.html"
                              >
                                Account Settings
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </li>
              </ul>
              {/* <div className="d-block d-lg-none">
                <img src="/logo.png" className="dark-logo" width="180" alt="" />
                <img src="/logo.png" className="light-logo" width="180"alt=""
                />
              </div> */}
              <button
                className="navbar-toggler p-0 border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="p-2">
                  <i className="ti ti-dots fs-7"></i>
                </span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNav"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <a
                    href="#"
                    className="nav-link d-flex d-lg-none align-items-center justify-content-center"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#mobilenavbar"
                    aria-controls="offcanvasWithBothOptions"
                  >
                    <i className="ti ti-align-justified fs-7"></i>
                  </a>
                  <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link notify-badge nav-icon-hover"
                        href="/#"
                        onClick={handleLogout}
                        title="Logout"
                      >
                        <i className="fa fa-sign-out"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
          <div className="container-fluid">{children}</div>
        </div>
        <div className="dark-transparent sidebartoggler"></div>
        <div className="dark-transparent sidebartoggler"></div>
      </div>

      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="mobilenavbar"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <nav className="sidebar-nav scroll-sidebar">
          <div className="offcanvas-header justify-content-between">
            <img src="/favicon.ico" alt="" className="img-fluid" />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body profile-dropdown mobile-navbar">
            <ul id="sidebarnav">
              <li className="sidebar-item">
                <a
                  className="sidebar-link has-arrow"
                  href="#"
                  aria-expanded="false"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>
                    <i className="ti ti-apps"></i>
                  </span>
                  <span className="hide-menu">Apps</span>
                </a>
                <ul aria-expanded="false" className="collapse first-level my-3">
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-chat.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">
                          Chat Application
                        </h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          New messages arrived
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-invoice.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">Invoice App</h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          Get latest invoice
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-mobile.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">
                          Contact Application
                        </h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          2 Unsaved Contacts
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-message-box.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">Email App</h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          Get new emails
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-cart.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">User Profile</h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          learn more information
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-date.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">Calendar App</h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          Get dates
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-lifebuoy.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">
                          Contact List Table
                        </h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          Add new contact
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="sidebar-item py-2">
                    <a href="#" className="d-flex align-items-center">
                      <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                        <img
                          src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/svgs/icon-dd-application.svg"
                          alt=""
                          className="img-fluid"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div className="d-inline-block">
                        <h6 className="mb-1 bg-hover-primary">
                          Notes Application
                        </h6>
                        <span className="fs-2 d-block fw-normal text-muted">
                          To-do and Daily tasks
                        </span>
                      </div>
                    </a>
                  </li>
                  <ul className="px-8 mt-7 mb-4">
                    <li className="sidebar-item mb-3">
                      <h5 className="fs-5 fw-semibold">Quick Links</h5>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        Pricing Page
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        Authentication Design
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        Register Now
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        404 Error Page
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        Notes App
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        User Application
                      </a>
                    </li>
                    <li className="sidebar-item py-2">
                      <a className="fw-semibold text-dark" href="#">
                        Account Settings
                      </a>
                    </li>
                  </ul>
                </ul>
              </li>
              <li className="sidebar-item">
                <a
                  className="sidebar-link"
                  href="/dashboard"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-message-dots"></i>
                  </span>
                  <span className="hide-menu">Home</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a
                  className="sidebar-link"
                  href="/designer"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-calendar"></i>
                  </span>
                  <span className="hide-menu">Form</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <div className="modal-content rounded-1">
            <div className="modal-header border-bottom">
              <input
                type="search"
                className="form-control fs-3"
                placeholder="Search here"
                id="search"
              />
              <span data-bs-dismiss="modal" className="lh-1 cursor-pointer">
                <i className="ti ti-x fs-5 ms-3"></i>
              </span>
            </div>
            <div className="modal-body message-body">
              <h5 className="mb-0 fs-5 p-1">Quick Page Links</h5>
              <ul className="list mb-0 py-2">
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Modern
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /dashboards/dashboard1
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Dashboard
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /dashboards/dashboard2
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Contacts
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/contacts
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Posts
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/blog/posts
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Detail
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/blog/detail/streaming-video-way-before-it-was-cool-go-dark-tomorrow
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Shop
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/ecommerce/shop
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Modern
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /dashboards/dashboard1
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Dashboard
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /dashboards/dashboard2
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Contacts
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/contacts
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Posts
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/blog/posts
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Detail
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/blog/detail/streaming-video-way-before-it-was-cool-go-dark-tomorrow
                    </span>
                  </a>
                </li>
                <li className="p-1 mb-1 bg-hover-light-black">
                  <a href="#">
                    <span className="fs-3 text-black fw-normal d-block">
                      Shop
                    </span>
                    <span className="fs-3 text-muted d-block">
                      /apps/ecommerce/shop
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center customizer-btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        <i
          className="ti ti-settings fs-7"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Settings"
        ></i>
      </button>
      <div
        className="offcanvas offcanvas-end customizer"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
          <h4
            className="offcanvas-title fw-semibold"
            id="offcanvasExampleLabel"
          >
            Settings
          </h4>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-4">
          <div className="theme-option pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Theme Option</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 light-theme text-dark"
              >
                <i className="ti ti-brightness-up fs-7 text-primary"></i>
                <span className="text-dark">Light</span>
              </a>
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 dark-theme text-dark"
              >
                <i className="ti ti-moon fs-7 "></i>
                <span className="text-dark">Dark</span>
              </a>
            </div>
          </div>
          <div className="theme-direction pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Theme Direction</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="index.html"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
              >
                <i className="ti ti-text-direction-ltr fs-6 text-primary"></i>
                <span className="text-dark">LTR</span>
              </a>
              <a
                href="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/html/rtl/index.html"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
              >
                <i className="ti ti-text-direction-rtl fs-6 text-dark"></i>
                <span className="text-dark">RTL</span>
              </a>
            </div>
          </div>
          <div className="theme-colors pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Theme Colors</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <ul className="list-unstyled mb-0 d-flex gap-3 flex-wrap change-colors">
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin1-bluetheme-primary active-theme "
                    data-color="blue_theme"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="BLUE_THEME"
                  >
                    <i className="ti ti-check text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin2-aquatheme-primary "
                    data-color="aqua_theme"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="AQUA_THEME"
                  >
                    <i className="ti ti-check  text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin3-purpletheme-primary"
                    data-color="purple_theme"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="PURPLE_THEME"
                  >
                    <i className="ti ti-check  text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin4-greentheme-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="GREEN_THEME"
                  >
                    <i className="ti ti-check  text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin5-cyantheme-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="CYAN_THEME"
                  >
                    <i className="ti ti-check  text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
                <li className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center justify-content-center">
                  <a
                    href="#"
                    className="rounded-circle position-relative d-block customizer-bgcolor skin6-orangetheme-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="ORANGE_THEME"
                  >
                    <i className="ti ti-check  text-white d-flex align-items-center justify-content-center fs-5"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="layout-type pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Layout Type</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="index.html"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
              >
                <i className="ti ti-layout-sidebar fs-6 text-primary"></i>
                <span className="text-dark">Vertical</span>
              </a>
              <a
                href="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/html/horizontal/index.html"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2"
              >
                <i className="ti ti-layout-navbar fs-6 text-dark"></i>
                <span className="text-dark">Horizontal</span>
              </a>
            </div>
          </div>
          <div className="container-option pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Container Option</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 boxed-width text-dark"
              >
                <i className="ti ti-layout-distribute-vertical fs-7 text-primary"></i>
                <span className="text-dark">Boxed</span>
              </a>
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 full-width text-dark"
              >
                <i className="ti ti-layout-distribute-horizontal fs-7"></i>
                <span className="text-dark">Full</span>
              </a>
            </div>
          </div>
          <div className="sidebar-type pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Sidebar Type</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 fullsidebar"
              >
                <i className="ti ti-layout-sidebar-right fs-7"></i>
                <span className="text-dark">Full</span>
              </a>
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center text-dark sidebartoggler gap-2"
              >
                <i className="ti ti-layout-sidebar fs-7"></i>
                <span className="text-dark">Collapse</span>
              </a>
            </div>
          </div>
          <div className="card-with pb-4">
            <h6 className="fw-semibold fs-4 mb-1">Card With</h6>
            <div className="d-flex align-items-center gap-3 my-3">
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 text-dark cardborder"
              >
                <i className="ti ti-border-outer fs-7"></i>
                <span className="text-dark">Border</span>
              </a>
              <a
                href="#"
                className="rounded-2 p-9 customizer-box hover-img d-flex align-items-center gap-2 cardshadow"
              >
                <i className="ti ti-border-none fs-7"></i>
                <span className="text-dark">Shadow</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
