const getHtmlTabsProp = [
  {
    key: "display",
    ignore: false,
  },
  {
    key: "api",
    ignore: false,
  },
  {
    key: "conditional",
    ignore: false,
  },
  {
    key: "logic",
    ignore: false,
  },
  {
    key: "layout",
    ignore: false,
  },
  {
    key: "customAPI",
    label: "Attributes",
    components: [
      {
        type: "textfield",
        key: "tableid",
        label: "DataTable Id",
        input: true,
        placeholder: "Enter DataTable Id",
        validate: {
          required: true,
        },
        tooltip: "This DataTable Id should be unique for current entire form",
      },
      {
        type: "textfield",
        key: "tableLabel",
        label: "Label",
        input: true,
        placeholder: "Enter the table lable name",
        validate: {
          required: true,
        },
      },
      {
        type: "checkbox",
        key: "tableisexcel",
        label: "Download Excel",
        input: true,
      },
      {
        type: "checkbox",
        key: "tableispdf",
        label: "Download PDF",
        input: true,
      },
      {
        type: "datagrid",
        key: "tableHeader",
        label: "Columns",
        addAnother: "Add Column",
        reorder: true,
        components: [
          {
            type: "textfield",
            key: "label",
            label: "Header Key",
            input: true,
            placeholder: "Culumn name",
            validate: {
              required: true,
            },
          },
          {
            type: "textfield",
            key: "labelvalue",
            label: "Header Value",
            input: true,
            placeholder: "Culumn value",
            validate: {
              required: true,
            },
          },
          {
            type: "selectboxes",
            key: "selectBoxes",
            label: "IsAction",
            input: true,
            values: [
              {
                label: "Edit",
                value: "edit",
                customOptions: {
                  classname: "btn btn-info",
                  iconname: '<i class="ti ti-pencil"></i>',
                },
              },
              {
                label: "Delete",
                value: "delete",
                customOptions: {
                  classname: "btn btn-danger",
                  iconname: '<i class="ti ti-trash"></i>',
                },
              },
            ],
          },
        ],
      },
      {
        type: "textfield",
        key: "apiUrl",
        label: "API URL",
        input: true,
        placeholder: "Enter the API URL",
        validate: {
          required: true,
        },
      },
    ],
  },
];

export default getHtmlTabsProp;
