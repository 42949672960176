import { appURL_superadmin } from "./config";

const apiRequest = async (url, method = "GET", body = null, headers = {}) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
  });
  const { ok } = response;
  const data = await response.json();
  return { ok, data, rawdata: response };
};

const apiRequestSuperAdmin = async (
  url = appURL_superadmin,
  method = "POST",
  body = {
    username: "admin",
    password: "csg@2024",
  },
  headers = {}
) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
  });
  const { ok } = response;
  const data = await response.json();

  if (ok) {
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
  return { ok, data, rawdata: response };
};

const apiRequest_nores = async (
  url,
  method = "GET",
  body = null,
  headers = {}
) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
  });
  const { ok, status } = response;
  return { ok, status };
};

export { apiRequest, apiRequestSuperAdmin, apiRequest_nores };
