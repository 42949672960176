// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dash-header{
font-size: xx-large;
font-style: oblique;
color: darkgreen;
font-weight: bolder;
text-align: center;
text-decoration: underline;
margin-bottom: 2%;
}

.dash-card-header{
font-size: large;
font-style: normal;
color: rgb(19, 68, 80);
font-weight: bolder;
text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/dashboard.css"],"names":[],"mappings":";AACA;AACA,mBAAmB;AACnB,mBAAmB;AACnB,gBAAgB;AAChB,mBAAmB;AACnB,kBAAkB;AAClB,0BAA0B;AAC1B,iBAAiB;AACjB;;AAEA;AACA,gBAAgB;AAChB,kBAAkB;AAClB,sBAAsB;AACtB,mBAAmB;AACnB,gBAAgB;AAChB","sourcesContent":["\n.dash-header{\nfont-size: xx-large;\nfont-style: oblique;\ncolor: darkgreen;\nfont-weight: bolder;\ntext-align: center;\ntext-decoration: underline;\nmargin-bottom: 2%;\n}\n\n.dash-card-header{\nfont-size: large;\nfont-style: normal;\ncolor: rgb(19, 68, 80);\nfont-weight: bolder;\ntext-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
