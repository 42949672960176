import React, { useState, useEffect } from "react";
//import "./css/dashboard.css";
import {
  apiRequest,
  apiRequest_nores,
  apiRequestSuperAdmin,
} from "../Utils/APICall";
import { appURL_project } from "../Utils/config";
import Loader from "../Utils/Loader";

const fetchData = async (appURL) => {
  try {
    const response = await apiRequest(appURL, "GET", null, {});
    const { ok } = response;
    const data = await response.data;
    return { ok, data };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const ProjectMaster = () => {
  const [projectlist, setProjectlist] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    name: "",
  });
  const [loaderloading, setloaderLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setloaderLoading(true);
      const result = await fetchData(appURL_project);
      if (result.ok) {
        setProjectlist(result.data);
      }
      setloaderLoading(false);
    };
    getData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let requestURl = "";
    let method = "";
    let state = "";

    requestURl = appURL_project;
    method = "POST";
    state = "submitted";

    const body = formData;
    await getResponse(requestURl, method, body);
  };

  const getResponse = async (requestURl, method, body) => {
    setloaderLoading(true);
    try {
      const res = await apiRequestSuperAdmin();
      const acc_token = localStorage.getItem("accessToken");
      if (res.ok) {
        const test = await apiRequest_nores(requestURl, method, body, {
          Authorization: "Bearer " + acc_token,
        });
      }

      if (method === "POST") {
        alert("Record Saved Successfully.");
        window.location.href = "";
      } else if (method === "PUT") {
        alert("Record Updated Successfully.");
        window.location.href = "";
      } else if (method === "DELETE") {
        alert("Record Deleted Successfully.");
        window.location.href = "";
      }
    } catch (error) {
      if (error.message === "440") {
        alert("Session expired.Please log in again.");
        localStorage.removeItem("token");
        window.location.href = "/";
      } else {
        alert("Bad Request , Please Try Again Later");
        window.location.href = "";
      }
    } finally {
      setloaderLoading(false);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      {loaderloading && <Loader />}
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="card w-100">
            <div className="card-header bg-info">
              <h4 className="mb-0 text-white card-title">Project master</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <label htmlFor="formTitle">Project Title</label>
                  <input
                    type="text"
                    id="formTitle"
                    className="form-control"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="formName">Project Name</label>
                  <input
                    type="text"
                    id="formName"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-3 col-6"></div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-6 col-12">
                  <table className="table table-bordered border-dark">
                    <thead className="table-warning border-dark">
                      <tr>
                        <th>#</th>
                        <th>Project Title</th>
                        <th>Project Name</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectlist ? (
                        projectlist.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.title}</td>
                              <td>{item.name}</td>
                              <td style={{ textAlign: "center" }}>
                                <a title="Edit" className="btn btn-primary">
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                <a title="Delete" className="btn btn-danger">
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>Loading...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row text-center">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectMaster;
