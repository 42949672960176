import React, { useState, useEffect } from "react";
import { apiRequest, apiRequest_nores } from "../Utils/APICall";
import {
  appURL_form,
  appURL_formlist,
  appURL_formworkflowmapper,
  appURL_camunda_workflowlist,
} from "../Utils/config";
import { getCamundaAccessToken } from "../Utils/generateToken";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import Loader from "../Utils/Loader";

const fetchFormListData = async (appURL) => {
  try {
    const response = await apiRequest(appURL, "GET", null, {});
    const { ok } = response;
    const data = await response.data;
    return { ok, data };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const fetchWorkflowListData = async (requestURl) => {
  try {
    const res = await getCamundaAccessToken();
    const acc_token = localStorage.getItem("camunda_accessToken");
    if (res.ok) {
      const response = await apiRequest(requestURl, "GET", null, {
        Authorization: "Bearer " + acc_token,
      });
      const { ok } = response;
      const data = await response.data;
      return { ok, data };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const fetchMapperList = async (appURL) => {
  try {
    const response = await apiRequest(appURL, "GET", null, {});
    const { ok } = response;
    const data = await response.data;
    return { ok, data };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const accepted_prop = [
  "textfield",
  "textarea",
  "email",
  "phoneNumber",
  "password",
  "number",
  "checkbox",
  "select",
  "radio",
  "selectboxes",
  "datetime",
  "day",
  "time",
  "currency",
  "file",
  "signature",
  "survey",
  "location",
];

const extractKeysAndLabels = (components) => {
  let results = [];

  const traverseComponents = (componentList) => {
    componentList.forEach((component) => {
      // Check if the component type matches the accepted types
      if (accepted_prop.includes(component.type)) {
        results.push({
          key: component.key,
          label: component.label || component.title || "N/A", // Use label, title, or "N/A" as a fallback
        });
      }

      // If the component contains nested components, recursively check them
      if (component.components && component.components.length > 0) {
        traverseComponents(component.components);
      }

      // If the component has columns, check components inside the columns
      if (component.columns && component.columns.length > 0) {
        component.columns.forEach((col) => {
          if (col.components && col.components.length > 0) {
            traverseComponents(col.components);
          }
        });
      }
    });
  };

  traverseComponents(components);
  return results;
};

const fetchFormCulumnList = async (appURL) => {
  try {
    const response = await apiRequest(appURL, "GET", null, {});
    const { ok } = response;
    const data = await response.data;

    const components = data.components;
    const matchingComponents = extractKeysAndLabels(components);

    return { ok, matchingComponents };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const FormWorkflowMapper = () => {
  const [formslist, setFormslist] = useState(null);
  const [workflowlist, setWorkflowlist] = useState(null);
  const [listdata, setListdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaderloading, setloaderLoading] = useState(false);
  const [culumnlist, setCulumnlist] = useState([]);
  const [formData, setFormData] = useState({
    formId: "",
    formName: "",
    description: "",
    workflowId: "",
    workflowName: "",
  });
  const [editItem, setEditItem] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setloaderLoading(true);
      const result = await fetchMapperList(appURL_formworkflowmapper);
      if (result.ok) {
        setListdata(result.data);
        await $(".text-inputs-searching").DataTable();
      }
      setloaderLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (editItem && formslist && workflowlist) {
      const selectedForm = formslist.find(
        (form) => form._id === editItem.form_id
      );
      const selectedWorkflow = workflowlist.find(
        (workflow) => workflow.id === editItem.process_key
      );

      if (!selectedForm || !selectedWorkflow) {
        console.error("Selected form or workflow not found in the list.");
        setloaderLoading(false);
        return;
      }

      const newFormData = {
        formId: selectedForm ? selectedForm._id : "",
        formName: selectedForm ? selectedForm.title : "",
        workflowId: selectedWorkflow ? selectedWorkflow.id : "",
        workflowName: selectedWorkflow ? selectedWorkflow.name : "",
        description: editItem.description,
        task_variable: selectedColumns.join(","),
      };

      setFormData(newFormData);
      setloaderLoading(false);
    }
  }, [editItem, formslist, workflowlist, selectedColumns]);

  const handleDropdownFocus = async () => {
    if (!formslist) {
      setLoading(true);
      const result = await fetchFormListData(appURL_formlist);
      if (result.ok) {
        setFormslist(result.data);
      }
      setLoading(false);
    }
  };

  const handleWorkflowFocus = async () => {
    if (!workflowlist) {
      setLoading(true);
      const result = await fetchWorkflowListData(appURL_camunda_workflowlist);
      if (result.ok) {
        setWorkflowlist(result.data);
      }
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, options } = e.target;

    setFormData((prevData) => {
      if (name === "formId") {
        const selectedForm = formslist.find((form) => form._id === value);

        if (selectedForm) {
          fetchFormCulumnList(`${appURL_form}/${selectedForm._id}`).then(
            (result) => {
              if (result && result.ok) {
                setCulumnlist(result.matchingComponents);
              }
            }
          );
        }

        return {
          ...prevData,
          formId: selectedForm._id,
          formName: selectedForm.title,
        };
      } else if (name === "workflowId") {
        const selectedWorkflow = workflowlist.find(
          (workflow) => workflow.id === value
        );
        return {
          ...prevData,
          workflowId: selectedWorkflow.id,
          workflowName: selectedWorkflow.name,
        };
      } else if (name === "culumnId") {
        const selectedOptions = Array.from(options)
          .filter((option) => option.selected)
          .map((option) => ({
            key: option.value,
            label: option.text,
          }));

        return {
          ...prevData,
          task_variable: selectedOptions,
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const handleSave = async () => {
    const body = {
      tenant_id: "f71a2932-095c-4431-9a96-684b88bded13",
      form_id: formData.formId,
      form_name: formData.formName,
      process_key: formData.workflowId,
      process_name: formData.workflowName,
      created_by: "sunil",
      modified_by: "",
      is_anonymous: false,
      deleted: false,
      version: 1,
      task_variable: formData.task_variable,
      process_tenant: "",
      form_type: "",
      parent_form_id: "",
      description: formData.description,
      status: "",
      comments: "",
    };
    if (editItem && editItem.id) {
      body.id = editItem.id;
    }

    try {
      setloaderLoading(true);
      let response;

      if (!editItem) {
        response = await apiRequest_nores(
          appURL_formworkflowmapper,
          "POST",
          body,
          {}
        );
      } else {
        response = await apiRequest_nores(
          `${appURL_formworkflowmapper}?id=eq.${editItem.id}`,
          "PUT",
          body,
          {}
        );
      }

      if (response.status === 201) {
        alert("Form-Workflow mapping saved successfully.");
        window.location.reload();
      } else if (response.status === 204) {
        alert("Form-Workflow mapping updated successfully.");
        window.location.reload();
      } else {
        alert(
          editItem
            ? "Form-Workflow update failed."
            : "Form-Workflow save failed."
        );
      }
      setloaderLoading(false);
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const handleEdit = async (item) => {
    try {
      setloaderLoading(true);
      setEditItem(item);

      console.log(item);

      if (!formslist) {
        const formsResult = await fetchFormListData(appURL_formlist);
        if (formsResult.ok) {
          setFormslist(formsResult.data);
        } else {
          console.error("Failed to fetch forms list");
          setloaderLoading(false);
          return;
        }
      }

      if (!workflowlist) {
        const workflowsResult = await fetchWorkflowListData(
          appURL_camunda_workflowlist
        );
        if (workflowsResult.ok) {
          setWorkflowlist(workflowsResult.data);
        } else {
          console.error("Failed to fetch workflows list");
          setloaderLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error("Error in handleEdit:", error);
      setloaderLoading(false);
    }
  };

  const handleDelete = async (item) => {
    try {
      let isConfirmed = window.confirm(
        "Are you sure want to delete this record?"
      );
      if (isConfirmed) {
        setloaderLoading(true);
        const response = await apiRequest_nores(
          `${appURL_formworkflowmapper}?id=eq.${item.id}`,
          "DELETE",
          null,
          {}
        );
        if (response.status === 204) {
          alert("Form-Workflow mapping deleted successfully.");
          window.location.reload();
        } else {
          alert("Form-Workflow mapping delete failed.");
        }
      }
      setloaderLoading(false);
    } catch (error) {
      console.error("Error in handleEdit:", error);
      setloaderLoading(false);
    }
  };

  return (
    <>
      {loaderloading && <Loader />}
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="card w-100">
            <div className="card-header bg-info">
              <h4 className="mb-0 text-white card-title">
                Form Workflow Mapper
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <label>Forms</label>
                  <select
                    className="form-control"
                    onFocus={handleDropdownFocus}
                    name="formId"
                    onChange={handleInputChange}
                    value={formData.formId}
                  >
                    <option>Select</option>
                    {loading ? (
                      <option>Loading...</option>
                    ) : (
                      formslist &&
                      formslist.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.title} - {item.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="col-lg-3 col-6">
                  <label>Workflows</label>
                  <select
                    className="form-control"
                    onFocus={handleWorkflowFocus}
                    name="workflowId"
                    onChange={handleInputChange}
                    value={formData.workflowId}
                  >
                    <option>Select</option>
                    {loading ? (
                      <option>Loading...</option>
                    ) : (
                      workflowlist &&
                      workflowlist.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={handleInputChange}
                    value={formData.description}
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="culumns">Culumns</label>
                  <select
                    className="form-select"
                    name="culumnId"
                    multiple={true}
                    onChange={handleInputChange}
                  >
                    <option>Select Culumn</option>
                    {Array.isArray(culumnlist) &&
                      culumnlist.map((culumn) => (
                        <option key={culumn.key} value={culumn.key}>
                          {culumn.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12 col-12">
                  <div className="table-responsive">
                    <table className="table table-bordered text-inputs-searching text-nowrap border-dark">
                      <thead className="table-warning border-dark">
                        <tr>
                          <th>#</th>
                          <th>Form Name</th>
                          <th>Workflow Name</th>
                          <th>Description</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listdata ? (
                          listdata.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.form_name}</td>
                                <td>{item.process_name}</td>
                                <td>{item.description}</td>
                                <td style={{ textAlign: "center" }}>
                                  <a
                                    title="Edit"
                                    className="btn btn-primary"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                  &nbsp;&nbsp;&nbsp;
                                  <a title="Delete" className="btn btn-danger">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() => handleDelete(item)}
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4}>Loading...</td>
                          </tr>
                        )}
                      </tbody>
                      {/* <tfoot>
                        <tr>
                          <th>-</th>
                          <th>Form Name</th>
                          <th>Workflow Name</th>
                          <th>-</th>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div className="row text-center">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormWorkflowMapper;
