import {
  appURL_login,
  CRED_EMAIL,
  CRED_PASSWORD,
  appURL_camunda_accesstoken,
} from "./config";
import { apiRequest } from "./APICall";

export const getToken = async () => {
  const formdataJson = {
    data: {
      email: CRED_EMAIL,
      password: CRED_PASSWORD,
    },
  };

  try {
    const response = await apiRequest(appURL_login, "POST", formdataJson, {});

    if (response && response.rawdata && response.rawdata.headers) {
      const token = response.rawdata.headers.get("x-jwt-token");
      return token;
    } else {
      console.error("Invalid response structure:", response);
      return null;
    }
  } catch (error) {
    console.error("Token Not Found:", error);
    return null;
  }
};

export const getCamundaAccessToken = async () => {
  try {
    const response = await fetch(appURL_camunda_accesstoken, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: "dialog-bpm",
        grant_type: "client_credentials",
        client_secret: "XPvL34sBHCmCHk8SORMZc5p5uMI198F8",
      }),
    });

    const { ok } = response;
    const data = await response.json();

    if (ok) {
      localStorage.setItem("camunda_accessToken", data.access_token);
    } else {
      localStorage.removeItem("camunda_accessToken");
    }
    return { ok, data, rawdata: response };
  } catch (error) {
    console.log("Error in API request:", error);
    throw error;
  }
};
