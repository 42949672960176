export const CRED_EMAIL = "admin@csgkarnataka.in";
export const CRED_PASSWORD = "csg@2024";
export const BASE_URL = "http://localhost";

export const BASE_URL_MongoDB = `http://designer-api.stridefuture.com`;
export const BASE_URL_bypass = `http://designer-webapi.stridefuture.com/api/v1`;

export const appURL_login = `${BASE_URL_MongoDB}/user/login`;
export const appURL_form = `${BASE_URL_bypass}/form`;
export const appURL_formlist = `${BASE_URL_bypass}/form?pageNo=1&limit=500000&sortBy=formName&sortOrder=asc`;
export const appURL_resourcelist = `${BASE_URL_bypass}/form?type=resource&pageNo=1&limit=500000&sortBy=formName&sortOrder=asc`;

export const appURL_projectfetch = `${BASE_URL_bypass}/project/f71a2932-095c-4431-9a96-684b88bded13`;
export const appURL_project = `${BASE_URL_bypass}/web/project`; //postgrest
export const appURL_superadmin = `${BASE_URL_bypass}/auth/admin`;
export const appURL_formworkflowmapper = `${BASE_URL_bypass}/web/formworkflowmapper`;

//export const appURL_camunda_accesstoken = `${BASE_URL}:8080/realms/dialog-platform-1/protocol/openid-connect/token`;
//export const appURL_camunda_workflowlist = `${BASE_URL}:8090/camunda/engine-rest-ext/v1/process-definition?latestVersion=true&excludeInternal=true&includeProcessDefinitionsWithoutTenantId=true&sortBy=tenantId&sortOrder=asc&maxResults=5`;
export const appURL_camunda_accesstoken = `https://csg-idm-dev.stridefuture.com/realms/dialog-platform-1/protocol/openid-connect/token`;
//export const appURL_camunda_workflowlist = `https://csg-workflow-dev.stridefuture.com/camunda/engine-rest-ext/v1/process-definition?latestVersion=true&excludeInternal=true&includeProcessDefinitionsWithoutTenantId=true&sortBy=tenantId&sortOrder=asc&maxResults=5`;
export const appURL_camunda_workflowlist = `https://csg-workflow-dev.stridefuture.com/camunda/engine-rest-ext/v1/process-definition?latestVersion=true&maxResults=50`;
