import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Dashboard from "./pages/dashboard";
import FormDesigner from "./pages/formdesigner";
import ProjectMaster from "./pages/ProjectMaster";
import FormWorkflowMapper from "./pages/formworkflowmapper";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<WithLayout />} />
      </Routes>
    </Router>
  );
};

const WithLayout = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/designer" element={<FormDesigner />} />
        <Route path="/project" element={<ProjectMaster />} />
        <Route path="/formworkflowmapper" element={<FormWorkflowMapper />} />
      </Routes>
    </Layout>
  );
};

export default App;
