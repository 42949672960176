import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../Utils/APICall";
import { appURL_login } from "../Utils/config";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  localStorage.removeItem("token");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const formdataJson = {
        data: {
          email: username,
          password: password,
        },
      };
      const response = await apiRequest(appURL_login, "POST", formdataJson, {});

      if (response && response.rawdata && response.rawdata.headers) {
        const token = response.rawdata.headers.get("x-jwt-token");
        localStorage.setItem("token", token);
        navigate("/dashboard");
      } else {
        alert("Invalid credential");
      }
    } catch (error) {
      alert("Error during login:");
    }
  };

  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="row justify-content-center w-100">
              <div className="col-md-4 col-lg-4 col-xl-3">
                <div className="card mb-0">
                  <div className="card-body">
                    <img
                      src="./logo.png"
                      className="dark-logo text-nowrap logo-img text-center d-block w-100"
                      width={180}
                      alt=""
                    />
                    <div
                      className="position-relative text-center my-4"
                      style={{ color: "#eb7900", fontWeight: "bold" }}
                    >
                      Login
                    </div>
                    <form onSubmit={handleLogin}>
                      <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input
                          type="email"
                          className="form-control"
                          id="loginusername"
                          aria-describedby="emailHelp"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="loginpassword"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                      >
                        Sign In
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
