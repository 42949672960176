import React, { useEffect, useState } from "react";
import "./css/dashboard.css";
import { apiRequest } from "../Utils/APICall";
import { appURL_formlist, appURL_resourcelist } from "../Utils/config";

const fetchData = async (appURL) => {
  try {
    const token = localStorage.getItem("token");
    const response = await apiRequest(appURL, "GET", null, {
      "x-jwt-token": token,
    });
    const { ok } = response;
    const data = await response.data;
    return { ok, data };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const Dashboard = () => {
  const [formslist, setFormslist] = useState(null);
  const [resourcelist, setResourcelist] = useState(null);
  const [searchTermForm, setSearchTermForm] = useState("");
  const [searchTermResource, setSearchTermResource] = useState("");

  useEffect(() => {
    const getData = async () => {
      const resultForms = await fetchData(appURL_formlist);
      if (resultForms.ok) {
        setFormslist(resultForms.data);
      }
      const resultResource = await fetchData(appURL_resourcelist);
      if (resultResource.ok) {
        setResourcelist(resultResource.data);
      }
    };
    getData();
  }, []);

  const filteredForms = formslist
    ? formslist.filter(
        (form) =>
          form.name.toLowerCase().includes(searchTermForm.toLowerCase()) ||
          form.title.toLowerCase().includes(searchTermForm.toLowerCase())
      )
    : [];

  const filteredResource = resourcelist
    ? resourcelist.filter(
        (resource) =>
          resource.name
            .toLowerCase()
            .includes(searchTermResource.toLowerCase()) ||
          resource.title
            .toLowerCase()
            .includes(searchTermResource.toLowerCase())
      )
    : [];

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-12">
          <h3 className="dash-header">
            Digital Infrastructure for Accessible Open Governance - Designer
          </h3>
        </div>
        <div className="col-lg-6 col-12">
          <div className="card">
            <div className="card-header bg-info d-flex align-items-center">
              <h4 className="mb-0 text-white card-title">Resources</h4>
              <div className="card-actions cursor-pointer ms-auto float-right">
                {/* <button className="btn btn-link text-white">+ Add</button> */}
                <input
                  type="text"
                  className="form-control"
                  style={{ background: "#fff" }}
                  placeholder="Search here"
                  value={searchTermResource}
                  onChange={(e) => setSearchTermResource(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="card-body">
              <div
                className="row"
                style={{
                  minHeight: "200px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#SN</th>
                      <th>Resources Name</th>
                      <th>Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredResource.length > 0 ? (
                      filteredResource.map((form, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{form.name}</td>
                          <td>
                            <a href={`/designer?_id=${form._id}`}>
                              {form.title}
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No results found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="card">
            <div className="card-header bg-primary d-flex align-items-center">
              <h4 className="mb-0 text-white card-title">Forms</h4>
              <div className="card-actions cursor-pointer ms-auto float-right">
                {/* <button className="btn btn-link text-white">+ Add</button> */}
                <input
                  type="text"
                  className="form-control"
                  style={{ background: "#fff" }}
                  placeholder="Search here"
                  value={searchTermForm}
                  onChange={(e) => setSearchTermForm(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="card-body">
              <div
                className="row"
                style={{
                  minHeight: "200px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#SN</th>
                      <th>Form Name</th>
                      <th>Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredForms.length > 0 ? (
                      filteredForms.map((form, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{form.name}</td>
                          <td>
                            <a href={`/designer?_id=${form._id}`}>
                              {form.title}
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No results found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
