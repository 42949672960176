import React, { useState, useEffect, useRef } from "react";
import { Formio, FormBuilder } from "react-formio";
//import { FormBuilder } from "@formio/react";
import { useLocation } from "react-router-dom";
import "formiojs/dist/formio.full.min.css";
// import "formiojs/dist/formio.form.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { apiRequest } from "../Utils/APICall";
import {
  BASE_URL_bypass,
  appURL_form,
  appURL_projectfetch,
} from "../Utils/config";
import getHtmlTabsProp from "../components/widget/editTabs";
import CustomDataTable from "../components/widget/CustomDataTable";

const FormDesigner = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const FormId = queryParams.get("_id");
  const [FormDetails, setFormDetails] = useState({
    name: "",
    path: "",
    title: "",
    display: "form",
    components: [],
  });
  const [isTokenSet, setIsTokenSet] = useState(false);
  const token = localStorage.getItem("token");
  const formBuilderRef = useRef(null);

  const customBuilder = {
    title: "Custom",
    weight: 50,
    components: {
      //customotpField: CustomOTPField,
      customdatatable: {
        title: "Custom Data Table",
        key: "customdatatable",
        icon: "html5",
        schema: CustomDataTable.schema(),
      },
    },
  };

  const options = {
    builder: {
      basic: {
        title: "Basic",
        default: true,
        weight: 0,
        components: {
          textfield: true,
          textarea: true,
        },
      },
      advanced: {
        title: "Advanced",
        weight: 10,
        components: {
          email: true,
          phoneNumber: true,
        },
      },
      data: {
        title: "Data",
        weight: 20,
        components: {
          datagrid: true,
          dataMap: true,
        },
      },
      layout: {
        title: "Layout",
        weight: 30,
        components: {
          columns: true,
          fieldset: true,
        },
      },
      custom: customBuilder,
    },
    editForm: {
      customdatatable: getHtmlTabsProp,
    },
    noDefaultSubmitButton: true,
  };

  useEffect(() => {
    if (FormId) {
      const fetchData = async (FormId) => {
        try {
          const response = await apiRequest(
            `${appURL_form}/${FormId}`,
            "GET",
            null,
            {
              "x-jwt-token": token,
            }
          );
          const data = await response.data;
          if (response.ok) {
            if (data.type === "resource") {
              data.display = "resource";
            }
            setFormDetails(data);
          }
        } catch (error) {
          console.error("Error fetching form details:", error);
        }
      };
      fetchData(FormId);
    }

    const initializeFormio = async () => {
      Formio.setBaseUrl(BASE_URL_bypass);
      Formio.setProjectUrl(appURL_projectfetch);
      Formio.setToken(token);
      setIsTokenSet(true);
    };
    initializeFormio();
  }, [FormId, token]);

  useEffect(() => {
    if (formBuilderRef.current && formBuilderRef.current.instance) {
      const builder = formBuilderRef.current.instance;

      builder.on("saveComponent", () => {
        if (builder.schema && builder.schema.components) {
          setFormDetails((prevDetails) => ({
            ...prevDetails,
            components: builder.schema.components,
          }));
        } else {
          console.error("No components found in the builder schema.");
        }
      });

      builder.on("removeComponent", () => {
        if (builder.schema && builder.schema.components) {
          setFormDetails((prevDetails) => ({
            ...prevDetails,
            components: builder.schema.components,
          }));
        } else {
          console.error("No components found in the builder schema.");
        }
      });
    }
  }, [isTokenSet]);

  useEffect(() => {
    if (formBuilderRef.current && formBuilderRef.current.instance) {
      formBuilderRef.current.instance.setForm(FormDetails);
    }
  }, [FormDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => {
      let newState = { ...prevState, [name]: value };

      if (name === "title") {
        const formattedText = value.toLowerCase().replace(/\s+/g, "");
        newState = {
          ...newState,
          name: formattedText,
          path: formattedText,
        };
      }

      return newState;
    });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleDisplayChange = (e) => {
    const newDisplayType = e.target.value;
    setFormDetails((prevState) => ({
      ...prevState,
      display: newDisplayType,
    }));
  };

  const handleSave = async () => {
    try {
      let response = {};
      if (!FormId) {
        response = await CallfinalSaveForm(token, FormDetails);
      } else {
        response = await CallfinalUpdateForm(token, FormId, FormDetails);
      }

      if (response.ok) {
        alert(FormId ? "Form updated successfully." : "New Form created.");
        window.location.reload();
      } else {
        const errorData = await response.data.message;
        alert(errorData);
        console.log("Error in response:", errorData);
      }
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const CallfinalSaveForm = async (headers, formJson) => {
    try {
      const response = await apiRequest(appURL_form, "POST", formJson, {
        "x-jwt-token": headers,
      });
      const { ok } = response;
      const data = await response.data;
      return { ok, data };
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const CallfinalUpdateForm = async (token, formId, formJson) => {
    try {
      const response = await apiRequest(
        `${appURL_form}/${formId}`,
        "PUT",
        formJson,
        {
          "x-jwt-token": token,
        }
      );
      return response;
    } catch (error) {
      console.error("Error updating form:", error);
      throw error;
    }
  };

  if (!isTokenSet) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="card w-100">
            <div className="card-header bg-info">
              <h4 className="mb-0 text-white card-title">Form Designer</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <label htmlFor="formTitle">Title</label>
                  <input
                    type="text"
                    id="formTitle"
                    className="form-control"
                    name="title"
                    value={FormDetails.title}
                    onChange={handleInputChange}
                    autoComplete="on"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="formName">Name</label>
                  <input
                    type="text"
                    id="formName"
                    className="form-control"
                    name="name"
                    value={FormDetails.name}
                    onChange={handleInputChange}
                    autoComplete="on"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="formPath">Path</label>
                  <input
                    type="text"
                    id="formPath"
                    className="form-control"
                    name="path"
                    value={FormDetails.path}
                    onChange={handleInputChange}
                    autoComplete="on"
                  />
                </div>
                <div className="col-lg-3 col-6">
                  <label htmlFor="formDisplay">Display Type</label>
                  <select
                    id="formDisplay"
                    className="form-control"
                    name="display"
                    value={FormDetails.display}
                    onChange={handleDisplayChange}
                  >
                    <option value="form">Form</option>
                    <option value="resource">Resource</option>
                    <option value="wizard">Wizard</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <FormBuilder
                ref={formBuilderRef}
                options={options}
                form={FormDetails}
              />
              {/* <div>
                <h3>Generated JSON:</h3>
                <pre>{JSON.stringify(FormDetails, null, 2)}</pre>
              </div> */}
            </div>
            <div className="card-footer">
              <div className="row text-center">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormDesigner;
